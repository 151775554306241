/**
 * App Styles
 */

.App {
  min-height: 100vh;
}

.container {
  width: 100%;
  margin: 0 auto;
  max-width: 640px;
}

textarea {
  border: 0;
  outline: 0;
  resize: none;
}

textarea:focus {
  background-color: #f7f7f7;
}

button {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  font-size: 16px;
  height: 2.5rem;
  background: white;
  appearance: none;
  transition: 0.2s linear background-color;
}

button:disabled {
  pointer-events: none;
}

.button-danger:hover {
  background-color: orangered;
}

.button-primary:hover {
  background-color: greenyellow;
}

.line-bg {
  background-image: repeating-linear-gradient(180deg, transparent 0px, transparent 23px, rgba(0, 0, 0, 0.1) 23px, rgba(0, 0, 0, 0.1) 24px);
  background-position: left top;
  padding: 24px 1rem;
}

.line {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  font-size: 16px;
  line-height: 1.5;
}

/**
 * Spacer Utils
 */

.spacer {
  width: 1rem;
  height: 1rem;
}

/**
 * Padding Utils
 */

.pb-8 {
  padding-bottom: 2rem;
}

.p-4 {
  padding: 1rem;
}

/**
 * Typography Utils
 */

.text-right {
  text-align: right;
}

.text-red {
  color: red;
}

.bold {
  font-weight: bold;
}

/**
 * Flex Utils
 */

.flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.flex-none {
  flex: none;
}

.flex-auto {
  flex: 1 1 auto;
}

.width-50 {
  width: 50%;
}
